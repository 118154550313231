export default [
  {
    path: '/metadata/upload',
    name: 'metadata-upload',
    component: () => import('@/views/authentication/PaymentandNextofKin.vue'),
    meta: {
      layout: 'full',
      resource: 'membership',
      action: 'manage',
    },
  },
  {
    path: '/content/upload/:type?',
    name: 'register-content-upload',
    component: () => import('@/views/authentication/RegistrationUploadContent.vue'),
    meta: {
      layout: 'full',
      resource: 'membership',
      action: 'manage',
    },
  },
  {
    path: '/payment/',
    name: 'register-payment',
    component: () => import('@/views/authentication/RegistrationPayment.vue'),
    meta: {
      layout: 'full',
      resource: 'membership',
      action: 'manage',
    },
  },
  {
    path: '/sound-records',
    name: 'member-sound-records',
    component: () => import('@/views/sound-records/SoundRecordList.vue'),
    meta: {
      resource: 'membership',
      action: 'manage',
    },
  },
  {
    path: '/audio-visuals',
    name: 'member-audio-visuals',
    component: () => import('@/views/audio-visuals/AudioVisualsList.vue'),
    meta: {
      resource: 'membership',
      action: 'manage',
    },
  },
  {
    path: '/content/:id/edit',
    name: 'content-edit',
    component: () => import('@/views/pages/content/EditContent.vue'),
    meta: {
      layout: 'full',
      resource: 'membership',
      action: 'manage',
    },
  },
  {
    path: '/musical-works',
    name: 'member-musical-works',
    component: () => import('@/views/musical-works/MusicalWorksList.vue'),
    meta: {
      resource: 'membership',
      action: 'manage',
    },
  },
]
